import { computed, ComputedRef } from 'vue'

import { useEndpoint } from '@zephyr/framework'

const { getConfig } = useEndpoint()

export const useDashboard = ({
  dashboardCode,
  componentList,
  currentWorkspace
} : {
  dashboardCode?: string,
  componentList?: Record<string, string>,
  currentWorkspace?: ComputedRef<any>
}) => {
  const config = getConfig()._config
  const getFederationComponent = async (component: string) => {
    const comp = await import(`@zephyr-remote/${component}`)
    return comp.default
  }

  const getLocalComponent = async (code: string) => {
    if (componentList) {
      // получаем из кода имя компонента без .ems и приводим к тому виду, в котором ключи в списке компонентов
      const component = `./${code.split('.')[0]}`
      // из полученного пути к нужному файлу убираем точку, чтобы путь стал абсолютным
      const importPath = componentList[component].slice(1)
      const comp = await import(importPath)
      return comp
    }
    return null
  }

  const getComponent = (code: string) => {
    // если работаем через федерацию, то импортируем компонент из нее
    if (window?.ZepRemoteModules) {
      return getFederationComponent(code)
    } else {
      // если локальная работа, то импортируем компонент локально
      return getLocalComponent(code)
    }
  }

  const dashboardList = computed(() => {
    return config?.portal?.dashboards?.filter((item: any) => item.workspace === currentWorkspace?.value?.code) ?? []
  })

  const currentDashboard = computed(() => {
    return (config?.portal?.dashboards?.find((item: any) => item.code === dashboardCode) || dashboardList.value[0])
      ?? defaultDashboard.value
  })

  const defaultDashboard = computed(() => config?.portal?.dashboards.find(i => i.code === config?.portal?.defaultDashboard))

  const dashboardLink = computed(() => {
    if (dashboardList.value?.length > 1) {
      console.log('useDashboards -> workspace have dashboardList', dashboardList.value)
      return { name: 'home', params: { workspace: currentWorkspace?.value?.code, code: currentDashboard.value?.code } }
    }
    return { name: 'dashboard', params: { workspace: currentWorkspace?.value?.code, code: currentDashboard.value?.code } }
  })

  return {
    getComponent,
    currentDashboard,
    defaultDashboard,
    dashboardList,
    dashboardLink
  }
}