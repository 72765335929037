
import { useProfile } from '@/composable/use-profile/useProfile'
import { loader } from '@/loader'
import { useWorkspace, useEndpoint, usePermissions, useRouterGenerator, useI18nService, IPortalRouter } from '@zephyr/framework'

import { useDashboard } from '@/utils/useDashboard'

export const createRoutes = async (portalRoutes: IPortalRouter[]) => {
  const { generateRoutes } = useRouterGenerator()
  const i18n = useI18nService()
  const additionalRoutes: IPortalRouter[] = [
    ...(portalRoutes ?? [])
  ]

  const loadedRoutes = await generateRoutes(additionalRoutes)

  const routes = [
    {
      path: '/',
      name: 'root',
      component: () => import('@/layouts/MainLayout/MainLayout.vue'),
      children: [
        {
          path: 'no-access',
          name: 'no-access-logged-in',
          component: () => import('@/pages/403/index.vue')
        },
        {
          path: ':workspace:*/dashboards',
          name: 'home',
          component: () => import('@/pages/Section.vue'),
          children: [
            {
              path: 'list',
              name: 'dashboard-list',
              meta: {
                title: i18n.t('dashboard.list'),
              },
              component: () => import('@/pages/Dashboard/DashboardList.vue'),
            },
            {
              path: ':code*',
              name: 'dashboard',
              props: true,
              meta: {
                title: i18n.t('dashboard.title'),
              },
              component: () => import('@/pages/Dashboard/Dashboard.vue')
            },
          ]
        },
        {
          path: 'profile',
          name: 'profile',
          redirect: {
            name: 'profile-view'
          },
          meta: {
            title: 'Профиль',
            options: {
              menuCode: 'profile',
            }
          },
          component: () => import('@/pages/Section.vue'),
          children: [
            {
              path: 'view',
              name: 'profile-view',
              meta: {
                title: 'Просмотр'
              },
              component: () => import('@/pages/Profile/Profile.vue')
            }
          ]
        },
        // ...workspaces
      ],
      // beforeEach: async (to, from) => {
      //   const { getAuthFlow } = useEndpoint()
      //   const { checkPermissionList, updating } = usePermissions()
      //   const { checkLoggedIn } = getAuthFlow()
      //   const isLoggedIn = await checkLoggedIn()
      //   const permissionsRequired = to?.meta?.permissions?.required

      //   console.log('Tenant Routing - waiting for updating permissions', { to, from })
      //   await updating
      //   console.log('Tenant Routing - updating permissions done')

      //   if (isLoggedIn) {
      //     const permissionsState = checkPermissionList(permissionsRequired)
      //     console.log('permissionsState', permissionsState)
      //     if (!permissionsState) {
      //       return { name: 'no-access-logged-in' }
      //     }

      //     loader.hide()
      //     console.log(`Route to ${to.name} is ${isLoggedIn ? 'allowed' : 'denied'}`, permissionsRequired)

      //     return true
      //   } else {
      //     return {
      //       name: 'login'
      //     }
      //   }
      // }
    },
    {
      path: '/auth',
      component: () => import('@/layouts/AuthLayout/AuthLayout.vue'),
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/pages/IAM/Login/Login.vue')
        }
      ]
    },
    {
      path: '/no-access',
      name: 'no-access',
      component: () => import('@/pages/403/index.vue')
    },
    {
      path: '/:catchAll(.*)*',
      name: 'not-found',
      component: () => import('@/pages/404/index.vue')
    }
  ]

  if (loadedRoutes?.length) {
    const rootRoute = routes.find(item => item.name === 'root')
    if (rootRoute && !rootRoute?.children?.length) {
      rootRoute.children = []
    }
    rootRoute?.children.push(...loadedRoutes)
  }

  console.log('Create routes => ', routes)

  return routes
}
