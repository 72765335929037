const permissions = [
  'authority-groups.read',
  'authority-groups.manage',
  'changelog.read',
  'users.read',
  'device-roles.manage',
  'regions.manage',
  'site-groups.manage',
  'sites.manage',
  'locations.manage',
  'manufacturers.manage',
  'device-types.manage',
  'device-models.manage',
  'contacts.read',
  'contacts.manage',
  'journal.read',
  'journal.manage',
  'tags.read',
  'tags.manage',
  'alarms.active.read',
  'alarms.active.manage',
  'alarms.history.read',
  'alarms.levels.manage',
  'alarms.categories.manage',
  'alarms.types.manage',
  'reports.active-alarms.generate',
  'reports.history-alarms.generate',
  'reports.my-task-generated.read',
  'reports.user-generated.read',
  'reports.task-generated.read',
  'reports.tasks.read',
  'reports.tasks.read',
  'reports.tasks.manage',
  'counters.read',
  'counters.manage',
  'racks.read',
  'racks.manage',
  'devices.read',
  'devices.manage',
  'drivers-state.read'
]
const codes = [
  1,
  2,
  3,
  4,
  6,
  7,
  8,
  9,
  10,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41
]
const backendFeatures = [
  'main',
  'main',
  'main',
  'main',
  'main',
  'main',
  'main',
  'main',
  'main',
  'main',
  'main',
  'main',
  'contacts',
  'contacts',
  'journal',
  'journal',
  'main',
  'main',
  'ems-main',
  'ems-main',
  'ems-main',
  'ems-main',
  'ems-main',
  'ems-main',
  'ems-main',
  'ems-main',
  'ems-main',
  'ems-main',
  'ems-main',
  'ems-main',
  'ems-main',
  'ems-main',
  'ems-main',
  'ems-main',
  'main',
  'main',
  'main',
  'main',
  'ems-main'
]

const backendModules = [
  ...new Array(39).fill('zep-ems-main'),
]

const apis = [
  'get /v1/authority-groups/ | get /v1/authority-groups/{id}',
  'get post /v1/authority-groups/ | get patch delete /v1/authority-groups/{id} | get /v1/users/ | get /v1/device-roles/ | get /v1/device-roles/{id} | get /v1/regions/ | get /v1/regions/{id} | get /v1/site-groups/| get /v1/site-groups/{id} | get /v1/sites/ | get /v1/sites/{id} | get /v1/locations/ | get /v1/locations/{id}',
  'get /v1/journal-changes/ | get /v1/journal-changes/{id}',
  'get /v1/users/',
  'get post /v1/device-roles/ | get patch delete /v1/device-roles/{id}',
  'get post /v1/regions/ | get patch delete /v1/regions/{id}',
  'get post /v1/site-groups/| get patch delete /v1/site-groups/{id}',
  'get post /v1/sites/ | get patch delete /v1/sites/{id}',
  'get post /v1/locations/ | get patch delete /v1/locations/{id}',
  'get post /v1/manufacturers/ | get patch delete /v1/manufacturers/{id}',
  'get post /v1/device-types/ | get patch delete /v1/device-types/{id}',
  'get post /v1/device-models/ | get patch delete /v1/device-models/{id}',
  'get /v1/contacts/ | get /v1/contacts/{id}',
  'get post /v1/contacts/ | get patch delete /v1/contacts/{id}',
  'get /v1/journal-entries/ | get /v1/journal-entries/{id}',
  'get post /v1/journal-entries/ | get patch delete /v1/journal-entries/{id}',
  'get /v1/tags/ | get /v1/tags/{id}',
  'get post /v1/tags/ | get patch delete /v1/tags/{id}',
  'get /v1/alarms/ | get /v1/alarms/{id}',
  'post /v1/alarms/{id}/ack | post /v1/alarms/{id}/unack',
  'get /v1/alarms/history | get /v1/alarms/history/{id}',
  'get post /v1/alarms-levels/ | get patch delete /v1/alarms-levels/{id}',
  'get post /v1/alarms-categories/ | get patch delete /v1/alarms-categories/{id}',
  'get post /v1/alarms-types/ | get patch delete /v1/alarms-types/{id}',
  'post /v1/reports/active-alarms/',
  'post /v1/reports/history-alarms/',
  'get /v1/reports/ | get /v1/reports/active-alarms/{id} | get /v1/reports/active-alarms/{id}/lines/ | get /v1/reports/active-alarms/{id}/status | get /v1/reports/history-alarms/{id} | get /v1/reports/history-alarms/{id}/lines/ | get /v1/reports/history-alarms/{id}/status',
  'get /v1/reports/ | get /v1/reports/active-alarms/{id} | get /v1/reports/active-alarms/{id}/lines/ | get /v1/reports/active-alarms/{id}/status | get /v1/reports/history-alarms/{id} | get /v1/reports/history-alarms/{id}/lines/ | get /v1/reports/history-alarms/{id}/status',
  'get /v1/reports/ | get /v1/reports/active-alarms/{id} | get /v1/reports/active-alarms/{id}/lines/ | get /v1/reports/active-alarms/{id}/status | get /v1/reports/history-alarms/{id} | get /v1/reports/history-alarms/{id}/lines/ | get /v1/reports/history-alarms/{id}/status',
  'get /v1/reports/ | get /v1/reports/active-alarms/{id} | get /v1/reports/active-alarms/{id}/lines/ | get /v1/reports/active-alarms/{id}/status | get /v1/reports/history-alarms/{id} | get /v1/reports/history-alarms/{id}/lines/ | get /v1/reports/history-alarms/{id}/status',
  '-',
  '-',
  'get /v1/counters/ | get /v1/counters/{id}',
  'get post /v1/counters/ | get patch delete /v1/counters/{id}',
  'get /v1/racks/ | get /v1/racks/{id}',
  'get post /v1/racks/ | get patch delete /v1/racks/{id}',
  'get /v1/devices/ | get /v1/devices/{id}',
  'get post /v1/devices/ | get patch delete /v1/devices/{id}'
]

export const permissionsList = permissions.map((permission, index) => {
  const code = codes[index] !== undefined ? codes[index] : null;
  const backendModule = backendModules[index] !== undefined ? backendModules[index] : null;
  const backendFeature = backendFeatures[index] !== undefined ? backendFeatures[index] : null;
  const apiString = apis[index] ?? '-';
  
  const api = apiString.split('|').flatMap(apiEntry => {
    const entries = apiEntry.trim().split(' ');
    const path = entries.pop(); // последний элемент - это путь
    const methods = entries; // остальные элементы - это методы

    return methods.map(method => ({
      method: method ?? null,
      path: path ?? null
    }));
  });

  return {
    permission: permission ?? null,
    code,
    backendModule,
    backendFeature,
    api
  }
})